.container-signup{
    background-image: url("../../../assets/Login/background.svg");
    background-image: url("../../../assets/Login/background-login-curve.svg");
    background-size: 100%;
    
    width: 100% ;
    height:100vh;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
}
.signup-cube{
    
    background-image: url("../../../assets/Login/Background-Big-Cube.svg");
   
    background-repeat: no-repeat;
    background-size: cover;
width: 30%;
height: 65%;
opacity: 0.55;
left: 10px;
margin-top: 150px;
position: absolute;
}
.signup-arrow{
    background-image: url("../../../assets/Login/Up-Arrow.svg");
   
    background-repeat: no-repeat;
    background-size: cover;
width: 70px;
height: 70px;
opacity: 0.55;
right:200px;
top:60px;
position: absolute;
}

.signup-container{

    
    z-index: 999;
    background-color: white;
    /* height: 70%; */
    border-radius: 10px;
    position: relative;
    width: 300px;
    
}

.container .signup-container > .title {
    display: flex;

    justify-content: center;
 

}

.container .signup-container > .signup-form {
    margin-top: 40px;
  
    /* padding-: 10px; */
    /* display: flex; */
    /* justify-content: center; */

}

.c-p{
    /* display: flex; */
    /* position: absolute; */
    /* bottom: 20px; */
 
}

.home {
    display: flex;
    justify-content: flex-start;
    margin-left: 93%;
    
    
    
    
}
/* .home:hover{
    cursor: pointer;
} */
input[type="checkbox"]:checked:before {
    background-color:green;
  }

  /* .passweye{
      margin-left: 90px;
  } */
  .passw{
   width: 220px;
}

/* Here media query written for 340px - 550px ************************** */

@media screen and (min-width: 340px) and (max-width:551px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 100% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 100%;
    height: 65%;
    opacity: 0.9;
    left: 10px;
    margin-top: 600px;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 40px;
    height: 40px;
    opacity: 0.55;
    right:60px;
    top:20px;
    position: absolute;
    }
    
    .signup-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 70%; */
        margin-bottom: 120px;
        border-radius: 10px;
        position: relative;
        width: 300px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
    }
}


/* Here media query written for 340px - 550px ************************** */

@media screen and (min-width: 340px) and (max-width:551px) and (height>600px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 100% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width:300px;
    height: 300px;
    opacity: 0.9;
    left: 10px;
    margin-top: 600px;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 70px;
    height: 70px;
    opacity: 0.55;
    right:60px;
    top:10px;
    position: absolute;
    }
    
    .signup-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 70%; */
        margin-bottom: 120px;
        border-radius: 10px;
        position: relative;
        width: 300px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
    }
}


/* Here media query written for 551px - 850px ************************** */

@media (min-width: 551px) and (max-width:850px) and (height<600px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 100% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 55%;
    height: 55%;
    opacity: 0.9;
    /* left: 10px; */
    right: 10%;
    margin: right 30px;;
    margin-bottom: 60%;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 60px;
    height: 60px;
    opacity: 0.55;
    right:60px;
    top:530px;
    position: absolute;
    }
    
    .signup-container{
    
        
        z-index: 999;
        margin-left: 10%;
        background-color: white;
        /* height: 70%; */
        margin-bottom:  18%;
        border-radius: 10px;
        position: relative;
        width: 300px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
    }
}

@media (min-width: 551px) and (max-width:850px) and (height>600px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 100% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 35%;
    height: 35%;
    opacity: 0.9;
    /* left: 10px; */
    right: 10%;
    margin: right 30px;;
    margin-bottom: 60%;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 60px;
    height: 60px;
    opacity: 0.55;
    right:60px;
    top:530px;
    position: absolute;
    }
    
    .signup-container{
    
        
        z-index: 999;
        margin-left: 10%;
        background-color: white;
        /* height: 70%; */
        margin-bottom:  18%;
        border-radius: 10px;
        position: relative;
        width: 320px;
        height: 420px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
    }
}

/* Here media query written for 850px - 1050px ************************** */

@media  (min-width: 849px) and (max-width:1050px) and (height>600px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 120% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
        
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 35%;
    height: 35%;
    opacity: 0.9;
    /* margin-right: 2000px; */
    /* left: 1px;
    right: 90%; */
    /* margin-right: 100px; */
    margin-bottom: 65%;
   margin-right: 180px;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 90px;
    height: 90px;
    opacity: 0.95;
    right: 10px;
    /* top:580px; */
    position: absolute;
    margin-right: 280px;
    margin-bottom: 1200px;
    }
    
    .signup-container{
    
        
        z-index: 999;
        /* margin-left: 2%; */
        background-color: white;
        /* height: 70%; */
        /* margin-bottom:  5%; */
        margin-top: 2%;
        border-radius: 10px;
        position: relative;
        width: 400px;
        height:500px;
        margin-right: 300px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
    }
}

/* Here media query written for above 1050px  ************************** */

@media  (min-width: 1049px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 120% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
        
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 45%;
    height:65%;
    opacity: 0.9;
    /* margin-right: 2000px; */
    /* left: 1px;
    right: 90%; */
    /* margin-right: 100px; */
    margin-bottom: 25%;
   /* margin-right: 380px; */
    position: absolute;
    /* right: 400px; */
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 110px;
    height: 110px;
    opacity: 0.95;
    right: 120px;
    /* top:580px; */
    position: absolute;
    margin-top: 5%;
    margin-right:20%;
    /* margin-bottom: 1100px; */
    }
    
    .signup-container{
    
        
        z-index: 999;
        /* margin-left: 2%; */
        background-color: white;
        /* height: 70%; */
        /* margin-bottom:  5%; */
        margin-top: 2%;
        border-radius: 10px;
        position: relative;
        width: 320px;
        height: 450px;
        margin-right: 300px;
        
    }
    
    .container .signup-container > .title {
        display: flex;

        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }

    .pt-2
    {
        margin-top: 12px;
    }

    .text-muted
    {
        margin-top: 10px;
    }
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */
     
    }
    /* .col
    {
        margin-top: 10px;
    } */
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
        
        margin-top: 15px;
        
        
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{
       width: 220px;
       
    }
}

/* Here media query written for below 340px ************************** */

@media (max-width:340px)
{
    .container-signup{
        position: fixed;
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        width: 120% ;
        height : 100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
        
    }
    .signup-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 20%;
    height: 20%;
    opacity: 0.9;
    /* margin-right: 2000px; */
    /* left: 1px;
    right: 90%; */
    /* margin-right: 100px; */
    /* margin-top: 120%; */
   margin-right: 180px;
   margin-bottom: 600px;
    position: absolute;
    }
    .signup-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
       
        background-repeat: no-repeat;
        background-size: cover;
    width: 30px;
    height: 30px;
    opacity: 0.95;
    right: 10px;
    /* top:580px; */
    position: absolute;
    margin-right: 120px;
     margin-bottom: 100%; 
    }
    
    .signup-container{
    
        
        z-index: 999;
        /* margin-left: 2%; */
        background-color: white;
        /* height: 70%; */
        /* margin-bottom:  5%; */
        margin-top: 5%;
        border-radius: 10px;
        position: relative;
        width: 70%;
        margin-right: 50px;
        
    }
    
    .container .signup-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .signup-container > .signup-form {
        margin-top: 40px;
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    /* .col
    {
        width: 10px;
        height: 40px;
    } */
    /* .row
    {
        width: 20px;
    } */
    
    .c-p{
        /* display: flex; */
        /* position: absolute; */
        /* bottom: 20px; */

     
    }
    
    .home {
        display: flex;
        justify-content: flex-start;
        margin-left: 93%;
    }
    
    .fc-email
    {
        width: 90%;
    }
   /* .text-muted
   {
    margin-top: 20px;
   } */
    /* .home:hover{
        cursor: pointer;
    } */
    input[type="checkbox"]:checked:before {
        background-color:green;
      }
    
      /* .passweye{
          margin-left: 90px;
      } */
      .passw{

       width: 140px;
    }
}