.main-content-body{
   width: 280px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-bottom: 15px;
}
.project_container{
    position: absolute;
    top: 90px;
    left: 90px;
    display: flex;
    flex-wrap: wrap;
    max-height: 87vh;
    overflow-y: scroll;
    scrollbar-width: none;
    width: 92vw;
    align-items: flex-start;
}