.nav{
    background-image: linear-gradient(to left, #4B54E5,#0812B0);
    border-radius: 23px;
    margin-left: 14px;
    background-color: aqua;
    margin-top: 10px;
    height: 64px;
    width:98%;
    z-index: 2;
    position: absolute;

}

.shadow-nav{
    box-shadow: 3px 3px 3px #181717;
}

#nav-ul ul{
    color: aliceblue;
    font-family:fantasy;
    font-size:x-large;
}
.logo{
    /* position: relative;
    left: 50px; */
    display: flex;
        align-items: flex-end;
}