.dashboard{
    /* position: absolute; */
    /* margin-top:100px; */
    /* overflow: hidden; */
    /* background-color: aqua; */
    width: 100%;
    /* height: 100vh; */

    height: 50%;
    overflow: hidden;
  
}

.dashboard-image{
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-image: url('../../assets/images/Dashboardtext.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.dashboard-image > img{
    height: 100%;
}

.dashboard-text{
    margin-top:10px;
}
.dashboard-text > div{
   align-items: center;
   align-content: center;
   justify-content: center;
}

.dash-button{
    display: flex;
    width: 200px;
    height: 50px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.go-to-app-btn{
    background-image: linear-gradient(to left, #4B54E5,#0812B0);
    /* background: linear-gradient(to right, # ); */
    padding: 8px 16px;
    border-radius: 16px;
    margin: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.go-to-app-btn:hover{
    transform: scale(1.2);
    transition: all 0.5s;
}

.go-to-app-btn > a{
    text-decoration: none;
    color: white;
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: 500;
}
.go-to-app-btn > a:hover{
    text-decoration: none;
    color: white;
}


/************************ media query between 549px and 851px *****************************/

@media (min-width:549px) and (max-width:851px)

{
    .container
    {
        position: fixed;
    }

    .dashboard{
        /* position: absolute; */
        /* margin-top:100px; */
        /* overflow: hidden; */
        /* background-color: aqua; */
        width: 100%;
        /* height: 100vh; */
    
        height: 50%;
        overflow: hidden;
      
    }
/*     
    .dashboard-image{
        width: 100%;
        height: 400px;
        overflow: hidden;
        background-image: url('../../assets/images/Dashboardtext.png');
        background-repeat: no-repeat;
        background-size: cover;
    } */
    
    .dashboard-image
    {
          position: relative;
          width: 118%;
          height: 416px;
          background-image: url('../../assets/images/Dashboardtext.png');
          background-repeat: no-repeat;
          background-position: center;
            
          
    }
    
    .dashboard-image > img{
        height: 100%;
    }
    
    .dashboard-text{
        margin-top:10px;
    }
    .dashboard-text > div{
       align-items: center;
       align-content: center;
       justify-content: center;
    }
    
    .dash-button{
        display: flex;
        width: 200px;
        height: 50px;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    
    .go-to-app-btn{
        background-image: linear-gradient(to left, #4B54E5,#0812B0);
        /* background: linear-gradient(to right, # ); */
        padding: 8px 16px;
        border-radius: 16px;
        margin: 10px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    
    .go-to-app-btn:hover{
        transform: scale(1.2);
        transition: all 0.5s;
    }
    
    .go-to-app-btn > a{
        text-decoration: none;
        color: white;
        font-size: 24px;
        letter-spacing: 2px;
        font-weight: 500;
    }
    .go-to-app-btn > a:hover{
        text-decoration: none;
        color: white;
    }
}



/************************ media query above 1200px *****************************/

@media (min-width:1200px)

{
    .dashboard{
        /* position: absolute; */
        /* margin-top:100px; */
        /* overflow: hidden; */
        /* background-color: aqua; */
        width: 100%;
        /* height: 100vh; */
    
        height: 50%;
        overflow: hidden;
      
    }
    
    .dashboard-image{
        position: relative;
        margin-left: 10%;
        width: 90%;
        height: 300px;
        overflow: hidden;
        background-image: url('../../assets/images/Dashboardtext.png');
        background-repeat: no-repeat;
        background-size: cover;
       
        background-position: center;
        margin-top: 2%;
        margin-top: 100px;
     
        background-position: 100%;
    }
    
    .dashboard-image > img{
        height: 100%;
    }
    
    .dashboard-text{
        margin-top:10px;
    }
    .dashboard-text > div{
       align-items: center;
       align-content: center;
       justify-content: center;
    }
    
    .dash-button{
        display: flex;
        width: 200px;
        height: 50px;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    
    .go-to-app-btn{
        background-image: linear-gradient(to left, #4B54E5,#0812B0);
        /* background: linear-gradient(to right, # ); */
        padding: 8px 16px;
        border-radius: 16px;
        margin: 10px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    
    .go-to-app-btn:hover{
        transform: scale(1.2);
        transition: all 0.5s;
    }
    
    .go-to-app-btn > a{
        text-decoration: none;
        color: white;
        font-size: 24px;
        letter-spacing: 2px;
        font-weight: 500;
    }
    .go-to-app-btn > a:hover{
        text-decoration: none;
        color: white;
    }
}
