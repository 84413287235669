.side-menu{
    
    background-image: linear-gradient(to top, #4B54E5,#0812B0);
    width: 64px;
    height: 97vh;
    border-radius: 23px;
    margin: 10px;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
}
.side-menu .top-section{
    position: fixed;
    top: 0px;
    height: 100px;
    /* background-color: '#000'; */
    
}

.side-menu .top-section .logo{
    
    /* position: absolute; */
    width: 40px;
    height: 40px;

    /* overflow: hidden; */
margin-top: 0%;
}

.side-menu .top-section .logo img {
    max-width: 100%;
    max-height: 100%;
}

 
.side-menu .divider{

    width: 100%;
    height: 0.5px;
    border-radius: 1px;
    background-color: #eee;
    opacity: 0.5;
    margin-bottom: 10px;
}

.side-menu .main-menu{
  position: absolute;
  top: 100px;

}

.side-menu .bottom-menu{
  position: absolute;
  bottom: 20px;
}
.menu-item{
    margin: 8px auto  ;
    display: flex;
    justify-content: center;
}
.folder-open
{
  margin: 30px;
}

.side-menu .main-menu .menu-item {

text-decoration: none;
font-size: 8px;
/* font-weight: 600; */
display: flex; 
 /* justify-content: center; */
color: rgb(77, 74, 74);
padding: 1px;
list-style: none;
}



.side-menu .main-menu .menu-item-name {
    color: '#fff ';
    /* display: flex; */
/* text-align: center; */
/* justify-content: center; */
    }

.side-menu .main-menu .menu-item .menu-icon{
    display: flex;
    width: 25px;
    height: 25px;
    font-size: 20px;
    line-height: 40px;
    /* text-align: center; */
    /* justify-content: center; */
    /* padding-bottom: 10px; */
    
}

.side-menu .side-menu-footer{
    display: flex;
    /* padding-top:10px; */
    width: 80px;
    height: 60px;
    position: fixed;
    background-color: var(--color-primary);
    border-top: 1px solid white;
    bottom: 0;
    left: 0;
    justify-content: center;
  
}

.side-menu .side-menu-footer .menu-item-name{
    text-align: center  ;
    padding: 0;
}

ul {
    list-style-type: none;
    margin: 10;
    padding: 10;
  }
  
  li a {
    display: block;
    width: 60px;
    background-color: #dddddd;
  }





.sidebar {
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
  }
  
  /* Sidebar links */
  .sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
  
  /* Active/current link */
  .sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  /* Links on mouse-over */
  .sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
  }
  
  /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }

  .side-menu .sidebar-item{
    position: absolute;
      bottom: 0;
  }
  
  /* On screens that are less than 700px wide, make the sidebar into a topbar */
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
  }
  
  /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
  }