.container-login{
    background-image: url("../../../assets/Login/background.svg");
    background-image: url("../../../assets/Login/background-login-curve.svg");
    background-size: 100%;
    
    width: 100% ;
    height:100vh;
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow: hidden;
}
.login-cube{
    
    background-image: url("../../../assets/Login/Background-Big-Cube.svg");
   
    background-repeat: no-repeat;
    background-size: cover;
    width: 30%;
    height: 65%;
    opacity: 0.55;
    left: 10px;
    margin-top: 150px;
    position: absolute;
}
.login-arrow{
    background-image: url("../../../assets/Login/Up-Arrow.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 70px;
    height: 70px;
    opacity: 0.55;
    right:200px;
    top:60px;
    position: absolute;
}
.home {
    display: flex;
    justify-content: flex-end;
}
.login-container{

    
    z-index: 999;
    background-color: white;
    height: 25rem;
    border-radius: 10px;
    position: relative;
    
}

.container .login-container > .title {
    display: flex;

    justify-content: center;
 

}

.container .login-container > .login-form {
    margin-top: 40px;
  
    /* padding-: 10px; */
    /* display: flex; */
    /* justify-content: center; */

}

.c-p{
    display: flex;
    position: absolute;
    bottom: 20px;
 
}

input[type="checkbox"]:checked:before {
    background-color:green;
}

/*1. Extra small devices (phones, 600px and down) */

@media  (width<=300px) and (height<=600px) and (orientation:portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 52%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-form
    {
        width: 100%;
        height: 80%;
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 80%;
        height: 75%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}
/* 2.Extra small devices (phones, 600px and down) */

@media  (width<=600px) and (height<=300px) and (orientation:landscape) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 52%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
   
   
    .login-form
    {
        /* background-color: red; */
        width: 100%;
        height: 100%;
        margin-bottom: 10%;
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        /* bottom: 10%; */
        width: 90%;
        height: 90%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
        background-color: red;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 10px;
        /* width: 120px; */
        /* background-color: blue; */
     
    }
    .login-heading
    {
        /* width: 20px; */
        height: 20px;
        font-weight: lighter;
        /* background-color: red; */
    }
    .mt-3
    {
        /* background-color: red; */
        margin-bottom: 10px;
        height: 30px;
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    /* .d-flex
    {
        margin-bottom: 20px;
        background-color: red;
    } */
    
}
/* 3.Extra small devices (phones, 600px and above) */

@media  (width<=300px) and (600px<height) and (orientation : Portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 62%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 80%;
        height: 65%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}


/* 4.Extra small devices (phones, 600px and above) */

@media  (600px>width>300px) and (600px<height) and (orientation : Portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 62%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 80%;
        height: 55%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}





/* 5.Extra small devices (phones, 600px and above landscape) */

@media  (width<=700px) and (height<=300px) and (orientation : Landscape) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 52%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 80%;
        height:75%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}

/*6. Extra small devices (phones, 600px and down) */

@media  (600px<width<=768px) and (300px<height<=600px) and (orientation:portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 40px;
        top: 52%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-form
    {
        width: 100%;
        height: 80%;
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 80%;
        height: 75%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}


/*7. Extra small devices (phones, 600px and down) */

@media  (600px<width<=780px) and (600px<=height) and (orientation:portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 130px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 130px;
        opacity: 0.55;
        left: 100px;
        top: 58%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-form
    {
        width: 100%;
        height: 80%;
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        opacity: 0.55;
        right:25%;
        top:85%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 55%;
        height: 45%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}


/*8. Extra small devices (phones, 600px and down) */

@media  (768px<width<=992px) and (600px<=height<=1400px) and (orientation:portrait) {
 
    
    .container-login{
        background-image: url("../../../assets/Login/background.svg");
        background-image: url("../../../assets/Login/background-login-curve.svg");
        background-size: 100%;
        
        width: 100% ;
        height:100vh;
        /* background-color: aqua; */
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        overflow: hidden;
    }
    .login-cube{
        
        background-image: url("../../../assets/Login/Background-Big-Cube.svg");
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 260px;
        /* margin-bottom: 130%; */
        /* margin-top: 20px; */
        height: 260px;
        opacity: 0.55;
        left: 100px;
        top: 68%;
        /* top:8%; */
        /* margin-top: 10px; */
        /* margin-bottom: 125%; */
        /* margin-top: 2%; */

        
    }
    .login-form
    {
        width: 100%;
        height: 80%;
    }
    .login-arrow{
        background-image: url("../../../assets/Login/Up-Arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 120px;
        height: 120px;
        opacity: 0.55;
        right:25%;
        top:80%;
        position: absolute;
    }
    .home {
        display: flex;
        justify-content: flex-end;
    }
    .login-container{
    
        
        z-index: 999;
        background-color: white;
        /* height: 25rem; */
        border-radius: 10px;
        position: relative;
        bottom: 10%;
        width: 45%;
        height: 45%;
    }
    
    .container .login-container > .title {
        display: flex;
    
        justify-content: center;
     
    
    }
    
    .container .login-container > .login-form {
        margin-top: 40px;
      
        /* padding-: 10px; */
        /* display: flex; */
        /* justify-content: center; */
    
    }
    
    .c-p{
        display: flex;
        position: absolute;
        bottom: 20px;
     
    }
    
    input[type="checkbox"]:checked:before {
        background-color:green;
    }
    
}