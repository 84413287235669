
.forget-setpass{
    /* z-index: 999; */
    background-color: white;
    height: 25rem;
    border-radius: 10px;
    /* position: relative; */
}

.reset-button{
   font-size: 20px;
   height: 10px;
   /* margin-top: 0px; */

}