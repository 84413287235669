.landing-container{
  margin: 0;
  padding: 0;
}

.landing-page {
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/blueBack.svg");
  /* background-image: url("../../assets/Login/background.svg"); */
  background-attachment: fixed;
  background-size: cover;
  background-size: 50%;
  /* width: 50%; */
  height:100vh;
  background-position: start;
  position: relative;
  z-index: 1;
}

.landing-bottom-bar{
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  /* background-image: url("../../assets/Login/background.svg"); */
  background-size: cover;
  background-size: 100%;
  width: 10%;
  height:20vh;
  /* background-color: #4B54E5; */
position: absolute;
  /* z-index: 999; */
right: 250px;
bottom: 0;

}


.landing-logo {
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Tcubelogo.png");
  /* background-image: url("../../assets/Login/background.svg"); */
  background-size: cover;
  background-size: 100%;
  width: 10%;
  height:100vh;
display: flex;
justify-content: space-between;
right:50px;
/* background-color: aqua; */
  position: absolute;
  flex-direction: row;

  z-index: 1;
}


.landing-bottombar{
  z-index: 999;
  position: absolute;
  display: flex;
  bottom: 10px;
  flex-direction: row;
  /* justify-content: space-around; */
  /* background-color: #4B54E5; */
  width: 100%;

 
}

.landing-aboutus{

  left: 10px;
  display: flex;
  flex-direction: row;
  color: #8A8A8A;
text-decoration: none;

 
}
.landing-contactus{
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  color: #8A8A8A;

 
}
.landing-poweredby{
/* position: relative; */
left: 10px;
display: flex;
flex-direction: row;
color: #8A8A8A;
}
.landing-xbi4-logo{
  position: relative;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/xbi4-logo.png");
  background-size: cover;
  background-size: 50%;
  width: 100px;
  height: 20px;
  top: 3px;
  left:10px;

  /* background-color: aqua; */

}

.landing-version{
  position: absolute;
  right: 10px;
  color: #8A8A8A;
}

.landing-background{
  display: flex;
  justify-content: flex-end;
right: 0;
/* background-color: aqua; */
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Background-Pixels.svg");
  /* background-attachment: fixed; */
  background-size: cover;
  background-size: 100%;
  width: 100%;
  height:100vh;

  position: relative;

}

.landing-background-color-cube{
  /* z-index: 1; */
 /* top: 0px; */
right: 0;
/* background-color: aqua; */
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Cube-Multi-Color.svg");
  /* background-attachment: fixed; */
  background-size: cover;
  background-size: 50%;
  width: 50%;
  height:100vh;

  position: absolute;

}

.landing-bargraph{


/* background-color: aqua; */
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Background-Bar-Graph.svg");
  background-attachment: fixed;
  background-size: contain;
  background-size: 10%;
  width: 100%;
  height:100vh;
  stroke-opacity: 0;
  position: absolute;
  bottom: 0px;
  /* color: #4B54E5; */

}

.cube{
    
  background-image: url("../../assets/Login/Background-Big-Cube.svg");
 
  background-repeat: no-repeat;
  background-size: cover;
width: 30%;
height: 50%;
opacity: 1;
left: 10px;
position: relative;
}

.landing-page-title{
  /* position: absolute; */
left: 10px;
top: 80%;
font-weight: bolder;
color: white;
font-size: xx-large;

}
.landing-page-subtitle{
  /* position: absolute; */
left: 0px;
/* top: 105%; */
font-weight: 100;
color: white;
font-size: xx-large;

}
.landing-page-smalltext{
  position: absolute;
/* left: 10px; */
top: 100%;
color: white;
font-size:small;

}
/* ******************************* Ami's code starts here ******************************* */ 

@media screen and (min-width: 769px) {
  .landing-button{
    position: absolute;
    display: flex;
    /* flex-direction: row ; */
    top: 150px; 
    /* background-color: black; */
    /* padding: 0px 10px 0px 10px; */
    left: 10px;
  }
} 
 @media screen and (min-width: 1024px)and (max-width: 1200px){ 
   .landing-button{ 
     /* position: absolute;  */
     display: flex; 
     /* flex-direction: row ;  */
     top: 150px; 
     /* background-color: black;  */
     /* padding: 0px 10px 0px 10px;  */
     left: 10px; 
  }
  .landing-page-title{ 
       position: absolute; 
     left: 10px; 
     top: 50px; 
     font-weight: bolder; 
     color: white; 
    font-size: xx-large; 
    
    
   } 
}
@media screen and (min-width: 1200px)and (max-width: 1900px){ 
   .landing-button{ 
     position: absolute; 
     display: flex; 
     /* flex-direction: row ;  */
     top: 500px; 
     /* background-color: black;  */
     /* padding: 0px 10px 0px 10px;  */
     left: 10px; 
 } 
 .landing-page-title{ 
  
     position: absolute; 
   left: 10px; 
   top: 200px; 
   font-weight: bolder; 
   color: white; 
   font-size: xx-large; 
  
  
} 
 } 
/* lenovo lap */
 @media screen and (min-width: 1200px)and (max-width: 1900px){ 
   .landing-button{ 
    position: absolute;
    display: flex;
    /* flex-direction: row ; */
    top: 510px;
    /* background-color: white; */
     /* padding: 0px 10px 0px 10px;  */
    left: 15px; 
  }
  .landing-page-title{
  
    position: absolute;
  left: 10px; 
  top: 200px;
  font-weight: bolder;
  color: white;
  font-size: xx-large;
  
  
 } 
}

/* Big screen login button */

@media screen and (min-width: 1900px){ 
   .landing-button{ 
     position: absolute; 
     display: flex; 
     /* flex-direction: row ;  */
     top: 570px; 
     /* background-color: black;  */
     /* padding: 0px 10px 0px 10px;  */
     padding: 5px 20px 5px 20px;
    left: 10px; 
   } 
   .landing-page-title{ 
  
    position: absolute;   
     left: 10px;
  top: 350px;
  font-weight: bolder;
  color: white;
  font-size: xx-large; 
} 
}

 .landing-signup{ 
  background-color: white;
  padding: 5px 30px 10px 30px ;
  justify-content: center;
  border-radius: 10px;
  font-size: larger;
  font-weight:bolder;
  /* font-weight: boldisplay: flex;
  backgrder; */
  color: #4B54E5;
  left: 1px; 
  /* background-image: linear-gradient(to right, #4B54E5,#0812B0); */
   /* display: flex;  */
   /* position: absolute;  */

   /* background-color: white; */
  /* font-size: larger; */
  /* font-weight: bolder; */
   /* color: #4B54E5;  */
   /* justify-content: center; */
  /* margin-left: 0px; */
  /* height: 40px; */
  /* padding: 5px 20px 5px 20px; */
  /* border: 1px solid rgb(255, 255, 255); */
  /* border-radius: 5px; */
  /* color: white; */
  /* bottom: 0; */
  /* left: 10px; */
/* width: 70px;  */
} 





 .landing-login{ 
  /* background-image: linear-gradient(to right, #4B54E5,#0812B0);
  display: flex;
  position: absolute;
  justify-content: center;
  margin-left: 0px;
  height: 40px;
  padding: 5px 20px 5px 20px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  color: white;
  bottom: 0;
  left: 120px; */

  
  display: flex;
  background-color: white;
  padding: 5px 30px 10px 30px ;
  justify-content: center;
  border-radius: 10px;
  font-size: larger;
  font-weight: bolder;
  color: #4B54E5;
  left: 130px; 
  

}


/*************************** MEDIA QUERY BELOW 300px AND HEIGHT BETWEEN 500-800px **************************************/


@media  (width<300px) and (500px<height<800px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 180%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
  font-size: 70%;
  padding-top: 20px;
}
.landing-background-color-cube{
  left: 80%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 270px;
  width: 90px;
}
.landing-login{
  margin-bottom: 270px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  /* background-image: url("../../assets/LandingPageImages/Background-Bar-Graph.svg"); */
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: cover;
  background-size: 100%;
  /* background-color: red; */
  left: 50px;
  bottom: 70px;
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 100px;
  height: 100px;
}
.landing-poweredby
{
  /* top: 10%; */
  font-size: 50%;
  width: 35%;
  padding-top: 10px;
  /* right: 10px; */
  align-self: center;
  /* margin-top: 20px; */
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  /* top: 10px; */
  left: 1px;
  width: 45%;
}
.landing-aboutus
{
  font-size: 50%;
  /* width: 15%; */
  height: 10%;
  align-self: center;
  padding-top: 5px;
  
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  font-size: 50%;
  /* width: 20%; */
  padding-top: 5px;
  padding-left: 27px;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */

  /* width: 25%; */
  align-self: center;
  padding-left: -10px;
  font-size: 50%;
  padding-top: 3px;
}

  
}


/*************************** MEDIA QUERY BELOW 300px AND HEIGHT BELOW 600px **************************************/


@media  (width<300px) and (height<600px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 140%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
  font-size: 70%;
  padding-top: 20px;
}
.landing-background-color-cube{
  left: 80%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 300px;
  width: 90px;
}
.landing-login{
  margin-bottom: 300px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  /* background-image: url("../../assets/LandingPageImages/Background-Bar-Graph.svg"); */
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: cover;
  background-size: 100%;
  /* background-color: red; */
  left: 50px;
  bottom: 30px;
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 100px;
  height: 100px;
}
.landing-poweredby
{
  /* top: 10%; */
  font-size: 50%;
  width: 35%;
  padding-top: 10px;
  /* right: 10px; */
  align-self: center;
  /* margin-top: 20px; */
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  /* top: 10px; */
  left: 1px;
  width: 45%;
}
.landing-aboutus
{
  font-size: 50%;
  /* width: 15%; */
  height: 10%;
  align-self: center;
  padding-top: 5px;
  
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  font-size: 50%;
  /* width: 20%; */
  padding-top: 5px;
  padding-left: 27px;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */

  /* width: 25%; */
  align-self: center;
  padding-left: -10px;
  font-size: 50%;
  padding-top: 3px;
}

  
}


/*************************** MEDIA QUERY BETWEEN 300px-600px AND HEIGHT LESS THAN 600px **************************************/


@media  (300px<=width<=600px) and (height<=600px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }

  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 120%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}
.landing-bottombar{
  /* bottom: 10px; */
  width: 100%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 250px;
  width: 90px;
}
.landing-login{
  margin-bottom: 250px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  
  flex-direction: row;
  width: 80%;
}
.landing-poweredby
{
  width: 35%;
  right: 10px;
  align-self: center;
  /* background-color: red; */
}

.landing-bottom-bar{
  background-repeat: no-repeat;
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  /* background-image: url("../../assets/Login/background.svg"); */
  background-size: cover;
  background-size: 100%;
  width: 10%;
  height:20vh;
  background-attachment: inherit;
  /* background-color: #4B54E5; */
position: absolute;
  /* z-index: 999; */
right: 250px;
bottom: 0;

}

.landing-xbi4-logo{
  top: 20px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 15%;
  align-self: center;
}
}

/*************************** MEDIA QUERY BETWEEN 300px-600px AND HEIGHT GREATER THAN 600px **************************************/


@media  (300px<=width<=600px) and (600px<height){ 
  .landing-container{
    position: fixed;
    background: fixed;
    width: 100%;
    height: 100%;
  }

  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 120%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}

.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 270px;
  width: 90px;
}
.landing-login{
  margin-bottom: 270px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  /* background-attachment: inherit; */
  background-size: cover;
  background-size: 100%;
  position: absolute;
  /* background-color: red; */
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 180px;
  /* margin-left: 10%; */
  top: 66%;
  /* background-color: red; */
  left: 39%;
}
.landing-poweredby
{
  width: 35%;
  right: 10px;
  align-self: center;
  /* background-color: red; */
}
.landing-xbi4-logo{
  top: 8px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 20%;
  align-self: center;
}
}

/* MEDIA QUERY ABOCE 600px and ABOVE HEIGHT 500px */

@media  (600px<=width<=900px) and (400px<height<800px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 80%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 270px;
  width: 90px;
}
.landing-login{
  margin-bottom: 270px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: contain;
  background-size: 100%;
  /* background-color: red; */
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 180px;
  /* margin-left: 10%; */
  top: 86%;
  left: 59%;
}
.landing-poweredby
{
  width: 25%;
  right: 10px;
  align-self: center;
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  top: 10px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 15%;
  align-self: center;
}

  
}




/*************************** MEDIA QUERY BETWEEN 300px-600px AND HEIGHT GREATER THAN 600px **************************************/


@media  (600px<=width<=900px) and (800px<height<1200px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 120%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 170px;
  width: 90px;
}
.landing-login{
  margin-bottom: 170px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: contain;
  background-size: 100%;
  /* background-color: red; */
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 180px;
  /* margin-left: 10%; */
  top: 86%;
  left: 59%;
}
.landing-poweredby
{
  width: 25%;
  right: 10px;
  align-self: center;
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  top: 10px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 15%;
  align-self: center;
}

  
}

/*************************** MEDIA QUERY BETWEEN 900px-1100px AND HEIGHT GREATER THAN 800px **************************************/


@media  (900px<width<=1000px) and (800px<height<1400px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 120%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 270px;
  width: 90px;
}
.landing-login{
  margin-bottom: 270px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  /* background-image: url("../../assets/LandingPageImages/Background-Bar-Graph.svg"); */
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: contain;
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 200px;
  top: 88%;
  left: 58%;
}
.landing-poweredby
{
  width: 25%;
  right: 10px;
  align-self: center;
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  top: 10px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 15%;
  align-self: center;
}

  
}


@media  (1000px<width<=1200px) and (500px<height<800px){ 
  .landing-container{
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .cube{
    width: 200px;
    height: 200px;
    
  }
  .landing-button{ 
    position: absolute; 
    display: flex; 
    /* flex-direction: row ;  */
    top: 570px; 
    /* background-color: black;  */
    /* padding: 0px 10px 0px 10px;  */
    padding: 5px 20px 5px 20px;
   left: 10px; 
  } 
  .landing-page {
    background-repeat: no-repeat;
    background-image: url("../../assets/LandingPageImages/blueBack.svg");
    /* background-image: url("../../assets/Login/background.svg"); */
    background-attachment: fixed;
    background-size: cover;
    background-size: 50%;
    /* width: 50%; */
    height:100vh;
    background-position: start;
    position: relative;
    z-index: 1;
  }
  .landing-page-title{ 
 
   position: absolute;   
    left: 5px;
 top: 1px;
 width: 300px; 
 /* width: 120%; */
 /* background-color: blue; */
 font-weight: bolder;
 color: white;
 font-size: xx-large; 
} 
.landing-page-subtitle{
  /* background-color: red; */
  bottom: 20%;
}
.landing-background-color-cube{
  left: 70%;
}
.px-2
{
  display: flexbox;
  
  /* backgrouznd-color: red; */
}
.landing-signup{
  margin-bottom: 270px;
  width: 90px;
}
.landing-login{
  margin-bottom: 270px;
  width: 90px;
}
.landing-bottom-bar{
  display: flexbox;
  /* background-image: url("../../assets/LandingPageImages/Background-Bar-Graph.svg"); */
  background-image: url("../../assets/LandingPageImages/Vector.svg");
  background-attachment: inherit;
  background-size: contain;
  background-size: 100%;
  /* flex-direction: row; */
  /* margin-left: 500px; */
  width: 200px;
  /* bottom: 10%; */
  /* background-color: red; */
}
.landing-poweredby
{
  width: 25%;
  right: 10px;
  align-self: center;
  /* background-colsor: aqua; */
}
.landing-xbi4-logo{
  top: 10px;
}
.landing-aboutus
{
  width: 15%;
  height: 10%;
  align-self: center;
  /* background-color: red; */
  /* margin-left: 10px; */
}
.landing-contactus
{
  width: 20%;
  align-self: center;
  /* background-color:brown; */
}
.landing-version{
  /* background-color: aqua; */
  width: 15%;
  align-self: center;
}

  
}

