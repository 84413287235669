.landing-page-container{
    background-image: url("../../../assets/LandingPageImages/Background-Pixels.svg");
    height: 100vh;
    background-size: cover;

}

.main-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
/* .left-container{
    background-image: url(../../../assets/LandingPageImages/blueBack.svg);
    height: 100vh;
    background-size: cover;
} */

.blue-bg-pattern{
    z-index: 1;
    top: 0px;
    /* position: relative; */
}

.blue-bg-pattern>svg{
    height: 90vh;
    width: 100%;
}

.left-container, .right-container{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-content{
    position: absolute;
    /* top: 0px; */
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width:760px){
    .main-container{
        display: flex;
        flex-direction: column;
    }
    .left-container, .right-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
}