body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c-p {
  cursor: pointer;
}

.f-b {
  font-weight: bold;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
