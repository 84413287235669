.home {
    display: flex;
    justify-content: flex-end;
}
.forget-box{
    z-index: 999;
    background-color: white;
    height: 45%;
    width: 65%;
    border-radius: 10px;
    position: relative;
}

.home {
    display: flex;
    justify-content: flex-end;
}



@media (300px<width<780px) and (height>600px)
{
    .forget-box{
        z-index: 999;
        background-color: white;
        height: 40%;
        width: 65%;
        border-radius: 10px;
        position: relative;
    }
    .login-cube
    {
        width: 220px;
        height: 220px;
        
    }
    .login-arrow
    {
        width: 70px;
        height: 70px;

    }

}

@media (1000px>width>700px) and (1200px>height>600px)
{
    .forget-box{
        z-index: 999;
        background-color: white;
        height: 30%;
        width: 45%;
        border-radius: 10px;
        position: relative;
    }
    .login-cube
    {
        width: 220px;
        height: 220px;
        
    }
    .login-arrow
    {
        width: 100px;
        height:100px;

    }

}

@media (1200px>width>1000px) and (1200px>height>400px)
{
    .forget-box{
        z-index: 999;
        background-color: white;
        height: 50%;
        width: 35%;
        border-radius: 10px;
        position: relative;
    }
    .login-cube
    {
        width: 220px;
        height: 220px;
        
    }
    .login-arrow
    {
        width: 80px;
        height:80px;

    }

}


@media (width>1200px) and (1200px>height>600px)
{
    .forget-box{
        z-index: 999;
        background-color: white;
        height: 30%;
        width: 25%;
        border-radius: 10px;
        position: relative;
    }
    .login-cube
    {
        width: 320px;
        height: 320px;
        left: 10%;
        
    }
    .login-arrow
    {
        width: 100px;
        height:100px;

    }

}

